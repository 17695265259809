<template>
  <div class="viewport">
    <div>
      <NuxtPage />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/breakpoints.scss';

.app-viewport-wrapper {
  margin: 0 auto;
  @include screen(lg) {
    padding-bottom: 5rem;
  }
}
.logo {
  width: fit-content;
  margin-top: 1rem;
  margin-right: 0.25rem;
}
.navigation {
  margin: 1.25rem 0 0.25rem 0;
}
.navigation > a {
  display: inline-flex;
  font-size: 1.5rem;
  margin-right: 1rem;
  font-weight: 500;
  color: var(--color-slate-500);
}
.navigation > a.router-link-active {
  color: var(--color-slate-700);
}

.page-enter-active,
.page-leave-active {
  transition: all 0.1s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(0.1rem);
}
</style>
